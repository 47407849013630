export default function ProgressBar(){

    return (


        

    <div className=" rounded-lg w-full mt-2  ml-1 h-2.5 bg-gray-300 flex items-center">
        <div className="w-full bg-white rounded-full h-2.5 dark:bg-gray-700 relative">
        </div>
    </div>


    )
}